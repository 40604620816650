import $axios from '@/plugins/axios'
const NotificationService = {
  async getHotelFireBaseStatus(hotel_id){
    try{
      const {data} = await $axios.get(`get-hotel-firebase-status/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async toggleFirebaseStatus(hotel_id){
    try{
      const {data} = await $axios.get(`toggle-firebase-activation/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async sendFirebaseNotification(payload){
    try{
      const {data} = await $axios.post(`send-firebase-notification`,payload);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getFirebaseClients(hotel_id){
    try{
      const {data} = await $axios.get(`get-firebase-clients/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getClientsStatistics(payload){
    try{
      const {data} = await $axios.get(`get-firebase-clients-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getMessagesStatistics(payload){
    try{
      const {data} = await $axios.get(`get-firebase-messages-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`);
      return data;
    }catch (e) {
      return e;
    }
  },
};
export default NotificationService;
